<template>
	<div>
		<div class="navBar">
			<div class="bottom-tab">
				<div v-for="(item,index) in tabBarImgArr" class="tab-item" @click="switchTo(item.url)">
					<img :src="item.url === $route.path ? item.selected : item.normal">
					<span :class="{on: item.url === $route.path}">{{item.name}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tabBarImgArr: [
					{
						normal: require('../assets/image/tabbar/home.png'),
						selected: require('../assets/image/tabbar/selectHome.png'),
						name:'首页',
						url:'/home'
					},
					{
						normal: require('../assets/image/tabbar/mine.png'),
						selected: require('../assets/image/tabbar/selectMine.png'),
						name:'我的',
						url:'/mine'
					},
				]
			}
		},
		methods: {
			switchTo(path) {
				this.$router.replace(path)
			}
		}
	}
</script>

<style scoped>
	.bottom-tab {
		width: 100%;
		height: 66px;
		display: flex;
		z-index: 5;
		position: fixed;
		bottom: 0;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(223, 223, 223, 0.5);
	}

	.tab-item {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		color: #979797;
	}

	img {
		width: 24px;
		height: 24px;
		margin-bottom: 2px;
	}

	.on {
		color: #DD1C25;
	}
</style>
