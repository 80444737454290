<template>
	<div>
		<div class="toper">
			<div class="headerTitle">2022瑞士冰雪节</div>
			<div class="flex align-center space-between nav">
				<div @click="goUrl(item)" class="navChild" v-for="(item,index) in navList" :key='index'>{{item.name}}
				</div>
			</div>
		</div>
		<div class="swiperWrap">
			<van-swipe @change="onChange" class="my-swipe" :autoplay="4000" indicator-color="white" :duration='1500'>
				<van-swipe-item v-for="(item,index) in bannerList" :key='index'>
					<img :src="host+item.banner_url" style="width: 100%;height: 120px;border-radius: 8px;">
				</van-swipe-item>
				<template #indicator>
					<div class="custom_wrap flex align-center">
						<div v-for="(item,index) in bannerList" :key='index'
							:class="current==index?'custom-indicator':'noSeCustom-indicator'"></div>
					</div>
				</template>
			</van-swipe>
		</div>
		<div class="listWrap flex space-between">
			<div class="leftWrap">
				<div class="mt_12 btm" v-for="(item,index) in leftList" :key='index' @click="goDetail(item,index)">
					<img :src="host+item.thumb" style="width: 100%;border-radius: 8px;">
					<div class="btmChild flex space-between align-center">
						<div class="commonWz" style="margin-left: 12px;">
							{{item.name}}
						</div>
						<div class="flex align-center" style="margin-right: 12px;">
							<img :src="require('../assets/image/home/watch.png')" class="images">
							<div class="commonWz">{{item.read_num}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightWrap">
				<div class="mt_12 btm" v-for="(item,index) in rightList" :key='index' @click="goDetail(item,index)">
					<img :src="host+item.thumb" style="width: 100%;border-radius: 8px;">
					<div class="btmChild flex space-between align-center">
						<div class="commonWz" style="margin-left: 12px;">
							{{item.name}}
						</div>
						<div class="flex align-center" style="margin-right: 12px;">
							<img :src="require('../assets/image/home/watch.png')" class="images">
							<div class="commonWz">{{item.read_num}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="zBox"></div>
		<TabBar></TabBar>
	</div>
</template>

<script>
	import{
		index,
		indexBanner
	} from '../api/home.js'
	import TabBar from '@/components/tabBar.vue'
	import{
		host
	} from '../utils/host.js'
	export default {
		name: 'Home',
		data() {
			return {
				current: 0,
				bannerList: [],
				navList: [{
						name: '推荐'
					},
					{
						name: '直播',
						url:'/liveBroadcast'
					},
					{
						id:1, //为了区分雪场和品牌
						name: '目的地',
						url:'/brandRecommend'
					},
					{
						id:2,
						name: '瑞士品牌',
						url:'/brandRecommend'
					},
					{
						name: '抽奖',
						url: '/luckDraw'
					}
				],
				leftList: [],
				rightList: [],
				host:host,
			}
		},
		components: {
			TabBar
		},
		created() {
			this.getList()
			this.getBanner()
		},
		methods: {
			goDetail(item,index){
				this.$router.push({
					path:'/brandDetail',
					query:{
						type:1,
						id:item.id
					}
				})
			},
			getBanner(){
				indexBanner().then(res=>{
					console.log('首页banner',res)
					this.bannerList = res.data
				})
			},
			onChange(e) {
				this.current = e
			},
			getList() {
				index().then(res=>{
					console.log(res,'---')
					let list = res.data
					for (var i = 0; i < list.length; i++) {
						if (i % 2 == 0) {
							this.leftList.push(list[i])
						} else {
							this.rightList.push(list[i])
						}
					}
					
				})
			},
			goUrl(item) {
				if (item.url) {
					this.$router.push({
						path: item.url,
						query: {
							type:item.id?item.id:''
						}
					})
				}
			}
		}
	}
</script>
<style scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.space-between {
		justify-content: space-between;
	}

	.custom_wrap {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 50%);
		bottom: 7%;
	}

	.custom-indicator {
		width: 6px;
		height: 2px;
		background: #FFFFFF;
		border-radius: 1px;
		margin-left: 4px;
	}

	.noSeCustom-indicator {
		width: 6px;
		height: 2px;
		background: #979797;
		border-radius: 1px;
		margin-left: 4px;
	}

	.noSeCustom-indicator:nth-child(1) {
		margin: 0;
	}

	.custom-indicator:nth-child(1) {
		margin: 0;
	}

	.toper {
		width: 100%;
		background: #DD1C25;
		height: 110px;
		padding: 40px 28px 0 28px;
		box-sizing: border-box;
		position: sticky;
		top: 0;
		z-index: 99;
	}

	.headerTitle {
		text-align: center;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
	}

	.nav {
		margin-top: 13px;
		color: #434444;
		font-size: 14px;
	}

	.navChild:nth-child(1) {
		color: #fff;
		position: relative;
	}

	.navChild:nth-child(1)::after {
		content: "";
		width: 100%;
		height: 4px;
		background: #FFFFFF;
		border-radius: 2px;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 50%);
		bottom: -15%;
	}

	.swiperWrap {
		margin: 14px 16px 4px 16px;
	}

	.listWrap {
		margin: 0 16px;
	}

	.leftWrap {
		width: 48%;
	}

	.rightWrap {
		width: 48%;
	}

	.btm {
		position: relative;
	}

	.btmChild {
		width: 100%;
		position: absolute;
		bottom: 10px;
	}

	.commonWz {
		color: #fff;
		font-size: 12px;
	}

	.images {
		width: 16px;
		height: 16px;
		margin-right: 4px;
		border-radius: 8px;
	}
</style>
