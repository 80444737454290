import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import flexble from './assets/js/flexble.js'
import initial from './assets/js/initial.js'
Vue.prototype.initial = initial
Vue.config.productionTip = false
import {
	throttle,
	Debounce
} from './assets/js/common.js'
Vue.prototype.$throttle = throttle
Vue.prototype.$Debounce = Debounce
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.use(Vant);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
