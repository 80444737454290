import request from "@/utils/request"
// 查询首页列表
export function index(data) {
	return request({
		url: '/api/Content/index',
		method: 'post',
		data
	})
}
// 商户品牌推荐
export function brandList(data) {
	return request({
		url: '/api/Content/brandList',
		method: 'post',
		data
	})
}
// 雪场列表
export function fieldList(data) {
	return request({
		url: '/api/Content/fieldList',
		method: 'post',
		data
	})
}
// 直播列表
export function liveBroadcast(data) {
	return request({
		url: '/api/Content/liveBroadcast',
		method: 'post',
		data
	})
}
// 获取首页banner
export function indexBanner(data) {
	return request({
		url: '/api/Banner/index',
		method: 'post',
		data
	})
}
// 直播列表页banner
export function liveBanner(data) {
	return request({
		url: '/api/Banner/liveBanner',
		method: 'post',
		data
	})
}
// 雪场/品牌详情
export function contentInfo(data) {
	return request({
		url: '/api/Content/contentInfo',
		method: 'post',
		data
	})
}
// 奖品列表
export function giftIndex(data) {
	return request({
		url: '/api/Lottery/index',
		method: 'post',
		data
	})
}
// 抽奖
export function lotterys(data) {
	return request({
		url: '/api/Lottery/lotterys',
		method: 'post',
		data
	})
}
// 随机五道题
export function questionIndex(data) {
	return request({
		url: '/api/Question/index',
		method: 'post',
		data
	})
}
// 增加抽奖次数
export function subQuestion(data) {
	return request({
		url: '/api/Question/subQuestion',
		method: 'post',
		data
	})
}
// 中奖记录
export function myPrize(data) {
	return request({
		url: '/api/User/myPrize',
		method: 'post',
		data
	})
}
// 获取抽奖次数
export function prizeNum(data) {
	return request({
		url: '/api/lottery/prizeNum',
		method: 'post',
		data
	})
}
// 奖品详情
export function prizeDetail(data) {
	return request({
		url: '/api/User/prizeDetail',
		method: 'post',
		data
	})
}
// 领奖
export function receive(data) {
	return request({
		url: '/api/User/receive',
		method: 'post',
		data
	})
}
// 活动说明
export function active(data) {
	return request({
		url: '/api/Lottery/active',
		method: 'post',
		data
	})
}
// 上传图片
export function upload(data) {
	return request({
		url: '/api/Common/upload',
		method: 'post',
		data
	})
}




