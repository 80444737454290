export default {
	// 获取宽高
	getWH() {
		let Width = document.documentElement.clientWidth
		let Height = document.documentElement.clientHeight
		return {
			Width,
			Height
		}
	},
	//验证手机号
	checkMobile(sMobile) {
		if (!(/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(sMobile))) {
			return false;
		} else {
			return true;
		};
	},
}
