import axios from 'axios'
import {
	Toast
} from 'vant'
const service = axios.create({
	baseURL: 'https://switzerland.lexuetiyu.com', // url = base url + request url
	header: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Content-Type': 'application/json;charset=UTF-8'
		// 'Content-Type':'Access-Control-Allow-Origin'
	},
	timeout: 50000, // request timeout
	dataType: 'json'
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent
		let token = sessionStorage.getItem('token')
		// console.log('token',token)
		if (token) {
			config.headers['token'] = store.getters.token || token
		}
		return config
	},
	error => {
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code !== 20000) {
			if (res.code == 1) {
				return res
			} else if (res.code == 0) {
				Toast(res.msg || 'Error');
				return Promise.reject(new Error(res.msg || 'Error'))
			}
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		Toast(error.msg || '网络连接失败');
		return Promise.reject(error)
	}
)
export default service
