import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'start',
		component: () => import('../views/start.vue')
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/login/register.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login.vue')
	},
	{
		path: '/luckDraw', //抽奖
		name: 'luckDraw',
		component: () => import('../views/luckDraw/luckDraw.vue')
	},
	{
		path: '/fillInAddress', //填写地址
		name: 'fillInAddress',
		component: () => import('../views/fillInAddress/fillInAddress.vue')
	},
	{
		path: '/giftReceive', //礼品领取
		name: 'giftReceive',
		component: () => import('../views/giftReceive/giftReceive.vue')
	},
	{
		path: '/giftHistory', //中奖记录
		name: 'giftHistory',
		component: () => import('../views/giftHistory/giftHistory.vue')
	},
	{
		path: '/mine', //我的
		name: 'mine',
		component: () => import('../views/mine/mine.vue')
	},
	{
		path: '/myAddress', //我的收货地址
		name: 'myAddress',
		component: () => import('../views/myAddress/myAddress.vue')
	},
	{
		path: '/mineInfo', //个人信息
		name: 'mineInfo',
		component: () => import('../views/mineInfo/mineInfo.vue')
	},
	{
		path: '/updateHeader', //修改头像
		name: 'updateHeader',
		component: () => import('../views/updateHeader/updateHeader.vue')
	},
	{
		path: '/liveBroadcast', //直播列表
		name: 'liveBroadcast',
		component: () => import('../views/liveBroadcast/liveBroadcast.vue')
	},
	{
		path: '/brandRecommend', //品牌推荐
		name: 'brandRecommend',
		component: () => import('../views/brandRecommend/brandRecommend.vue')
	},
	{
		path: '/brandDetail', //品牌详情
		name: 'brandDetail',
		component: () => import('../views/brandDetail/brandDetail.vue')
	},
]

const router = new VueRouter({
	routes
})

export default router
